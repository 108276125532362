<template>
  <b-row>
    <b-col cols="12" sm="8">
      <b-card>
        <h4>Help Center</h4>
        <p>Welcome to the Help Center.  We'll be constantly expanding this section to offer information about how to use the software and helpful video tutorials.  If you have any questions, contact michael@ihfsoftware.com</p>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'Help',
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  watch: {

  },
  created () {

  }
}
</script>
